import { Inject, Injectable } from '@angular/core';
import { appconstant } from '../../environments/app.constant';
import { PTA_CONST } from '../layout/pta/constants/pta.constant';
import { MII_CONST } from '../layout/menu-item-inactivation/constants/menu-item-inactivation.constant';
import { OOHOMEPAGE_CONST } from '../layout/owner-operator-homepage/owner-operator-homepage.constant';
import { HEADER_CONST } from '../layout/shared-modules/owner-operator-header/owner-operator-header.constant';
import { SYSMSG_CONST } from '../layout/owner-operator-homepage/owner-operator-system-messages/owner-operator-system-messages.constant';
import { MSG_CONST } from '../layout/message-creation/constants/message-creation.constant';
import { ACTION_CONST } from '../layout/action-item/constants/action-item.constant';
import { Router } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { POS_SLECTION_CONSTANT } from '../layout/PosLayoutDetails/constant/pos.constant';
import { PG_CONSTANTS } from '../layout/package-generation/model/packagegeneration.constant';
import { ReportTypeConstant } from '../layout/generate-report/report-type/report-type.constant';
import { MMID_CONSTANTS } from '../layout/mass-mi-delete/mass-mi-delete.constant';

declare var analyticsData: any;
declare var _satellite : any;

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsService {

  trackingKeys = appconstant.TRACKINGKEYS;

  constructor(
    private router: Router,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {}

  login(userEid){
    _satellite.setCookie(userEid,"Signed In",0);
    analyticsData.user.profileID = _satellite.readCookie(userEid);
  }

  logout(userEid){
    _satellite.setCookie(userEid,"Signed Out",0);
    analyticsData.user.profileID = _satellite.readCookie(userEid);
  }

  trackPageView() {
    _satellite.track('pageViewed');
    this.login(this.sessionStorage.get("userEid"));
  }

  trackUserAction() {
    _satellite.track('userAction');
  }

  setAnalyticsData(data) {
    for (var key in data) {
      analyticsData.page[key] = data[key];  
    }
    analyticsData.page.locale = this.sessionStorage.get("selectedLanguage");
    analyticsData.site.marketId = this.sessionStorage.get("marketName");
    analyticsData.site.platform = this.sessionStorage.get("deviceType");
  }

  resetAnalyticsData() {
    this.trackingKeys.forEach(key => {
      analyticsData.page[key] = '';
    });
  }

  setAndApplyPageViewdata(pageName, pageType, addInfo) {
    let trackData = {
      "pageName": pageName,
      "pageType": pageType,
      "pageUrl": this.router.url,
      "addInfo": addInfo
    };
    this.setAnalyticsData(trackData);
    this.trackPageView();
  }

  trackGenericPageView(origin, addInfo) {
    var pageName;
    var pageType;
    var addInfo;

    switch (origin) {
      case 'OOHome': {
        pageName = OOHOMEPAGE_CONST.pageNameLoggedIn;
        pageType = OOHOMEPAGE_CONST.pageType;
        break;
      }
      case 'PTA': {
        pageName = PTA_CONST.pageName;
        pageType = appconstant.pageType;
        break;
      }
      case 'MII': {
        pageName = MII_CONST.pageName;
        pageType = appconstant.pageType;
        break;
      }
      case 'Reports': {
        pageName = ReportTypeConstant.pageName;
        pageType = appconstant.pageType;
        break;
      }
      case 'POS': {
        pageName = POS_SLECTION_CONSTANT.pageName;
        pageType = appconstant.pageType;
        break;
      }
      case 'Package Generation': {
        pageName = PG_CONSTANTS.pageName;
        pageType = appconstant.pageType;
        addInfo = addInfo;
        break;
      }
      case 'Generated Packages': {
        pageName = PG_CONSTANTS.generatedPageName;
        pageType = appconstant.pageType;
        addInfo = addInfo;
        break;
      }
      case 'Messages': {
        pageName = MSG_CONST.Messages;
        pageType = appconstant.pageType;
        break;
      }
      case 'Message Management': {
        pageName = MSG_CONST.Messages + ": " + MSG_CONST.MSG_MGMT;
        pageType = appconstant.pageType;
        break;
      }      
      case "Create Message": {
        pageName = MSG_CONST.Messages + ": " + MSG_CONST.MSG_MGMT + ": " + origin;
        pageType = appconstant.pageType;
        break;
      }
      case "Edit Message": {
        pageName = MSG_CONST.Messages + ": " + MSG_CONST.MSG_MGMT + ": " + origin;
        pageType = appconstant.pageType;
        break;
      }
      case "Action Item": {
        pageName = ACTION_CONST.ActionItemPage;
        pageType = appconstant.pageType;
        break;
      }
      case "PTA Interim": {
        pageName = ACTION_CONST.PTAInterimPage;
        pageType = appconstant.pageType;
        break;
      }
      case "Create Action Item": {
        pageName = ACTION_CONST.ActionItemPage + ": " + ACTION_CONST.ActionItemCreation;
        pageType = appconstant.pageType;
        break;
      }
      case "MIIConfirm": {
        pageName = MII_CONST.pageName + ": " + MII_CONST.confirmation;
        pageType = appconstant.pageType;
        break;
      }
      case "MII Interim": {
        pageName = ACTION_CONST.MIIInterimPage;
        pageType = appconstant.pageType;
        break;
      }
      case "MMID": {
        pageName = appconstant.MMIDPageName;
        pageType = appconstant.pageType;
        break;
      }
    }
    this.resetAnalyticsData();
    this.setAndApplyPageViewdata(pageName, pageType, addInfo);
  }

   setAndApplyTrackdata(clickEvent) {
    this.resetAnalyticsData();
    let trackData = {};

    this.trackingKeys.forEach(key => {
      switch(key) {
        case 'clickName':
          if(clickEvent.includes(ACTION_CONST.ClickSendMail) || clickEvent.includes(ACTION_CONST.ClickCancelMail)){
            if(clickEvent.includes(ACTION_CONST.ClickCancelMail)){
              trackData[key] = ACTION_CONST.ClickCancel;
            }
            else if(clickEvent.includes(ACTION_CONST.ClickSendMail)){
              trackData[key] = ACTION_CONST.ClickSend;
            }
          }
          else if(clickEvent.includes(ACTION_CONST.InactivateEventYesConfirmation)){
            trackData[key] = ACTION_CONST.Yes;
          }
          else{
            trackData[key] = clickEvent;
          }
          break;
        case 'pageUrl':
          if(clickEvent.includes(ACTION_CONST.ClickSendMail)
              || clickEvent.includes(ACTION_CONST.ClickCancelMail)
              || ((clickEvent.includes(ACTION_CONST.ClickSave) && this.router.url.includes('/menu-item-inactivation/inactivation')))
              || clickEvent.includes(ACTION_CONST.InactivateEventYesConfirmation)){
            trackData[key] = "/owner-operator-homepage";
          }
          else{
            trackData[key] = this.router.url;
          }
          break;
        case 'pageName':
          var pageName = this.getPageName(this.router.url);
          if((clickEvent.includes(MSG_CONST.Messages) || clickEvent.includes(appconstant.openEvent)) && pageName.includes(OOHOMEPAGE_CONST.pageName)){
            pageName = OOHOMEPAGE_CONST.pageNameLoggedIn;
          }
          else if(pageName.includes('Menu Item Inactivation: Confirmation') && (clickEvent.includes(ACTION_CONST.ClickSendMail) || clickEvent.includes(ACTION_CONST.ClickCancelMail))){
            pageName = "Menu Item Inactivation: Email Export";
          }
          if(clickEvent.includes(appconstant.finishEvent) && pageName.includes(PG_CONSTANTS.pageName)){
            trackData[key] = PG_CONSTANTS.confirmationPageName;
          }
          else if(clickEvent.includes(appconstant.viewEvent) && pageName.includes(PG_CONSTANTS.pageName)){
            trackData[key] = PG_CONSTANTS.generatedPageName;
          }
          else if(clickEvent.includes(appconstant.generateNewEvent) && pageName.includes(PG_CONSTANTS.pageName)){
            trackData[key] = PG_CONSTANTS.generatedPageName;
          }
          else{
            trackData[key] = pageName;
          }
          break;
        case 'pageType':
          if(this.router.url.includes('owner-operator-homepage')){
            trackData[key] = OOHOMEPAGE_CONST.pageType;
          }
          else if(this.router.url.includes('/menu-item-inactivation/confirmation')
                && (clickEvent.includes(ACTION_CONST.ClickSendMail)
                    || clickEvent.includes(ACTION_CONST.ClickCancelMail)
                    || clickEvent.includes(ACTION_CONST.InactivateEventYesConfirmation))){
            trackData[key] = OOHOMEPAGE_CONST.pageType;
          }
          else if(this.router.url.includes('/menu-item-inactivation/inactivation')
                && (clickEvent.includes(ACTION_CONST.ClickSave)
                || clickEvent.includes(ACTION_CONST.InactivateEventYesConfirmation))){
            trackData[key] = appconstant.pageType;
          }
          else{
            trackData[key] = appconstant.pageType;
          }
          break;
        case 'linkType':
          if(clickEvent.includes("Alert") || clickEvent.includes(appconstant.messageHeaderEvent)){
            trackData[key] = appconstant.iconLinkType;
          }
          else if(clickEvent.includes(ACTION_CONST.ClickSendMail) || clickEvent.includes(ACTION_CONST.ClickCancelMail) || clickEvent.includes("Open:Reports")){
            trackData[key] = appconstant.ctaLinkType;
          }    
          else if(clickEvent.includes("Report") || clickEvent.includes("PDF") || clickEvent.includes("CSV") || clickEvent.includes("Email")){
            trackData[key] = appconstant.dropdownLinkType;
          }
          else if(this.router.url.includes('messages')){
            trackData[key] = appconstant.linkType;
          }      
          else{
            trackData[key] = appconstant.ctaLinkType;
          }          
          break;
        case 'clickTrackSection':
          if(this.router.url.includes('message-management') && clickEvent.includes(appconstant.messageEvent)){  
            trackData[key] = "Message";
          }
          else if(clickEvent.includes(appconstant.messageHeaderEvent)){ 
            trackData[key] = "Header";
          }
          else if(clickEvent.includes(ACTION_CONST.ClickSendMail)
                  || clickEvent.includes(ACTION_CONST.ClickCancelMail)
                  || ((clickEvent.includes(ACTION_CONST.ClickSave) && this.router.url.includes('/menu-item-inactivation/inactivation')))
                  || clickEvent.includes(ACTION_CONST.InactivateEventYesConfirmation)){
            trackData[key] = "Pop-up";
          }
          else{
            trackData[key] = appconstant.clickTrackSection;
          }          
          break;          
        default:
          trackData[key] = appconstant[key];
      }
    });

    this.setAnalyticsData(trackData);
    this.trackUserAction();
  }

  trackEffectiveDateNextClick() {
    let clickStr = appconstant.nextEvent + appconstant.effDateEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackSelectRestaurantNextClick() {
    let clickStr = appconstant.nextEvent + appconstant.restaurantEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackMenuItemNextClick() {
    let clickStr = appconstant.nextEvent + appconstant.menuItemEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackPriceNextClick() {
    let clickStr = appconstant.nextEvent + appconstant.priceEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackActivatePriceClick(activate) {
    let clickStr = '';
    if(activate) {
      clickStr = appconstant.activateEvent + appconstant.priceEvent;
    } else {
      clickStr = appconstant.applyEvent + appconstant.priceEvent;
    }
    this.setAndApplyTrackdata(clickStr);
  }

  trackActivateTaxClick(activate) {
    let clickStr = '';
    if(activate) {
      clickStr = appconstant.activateEvent + appconstant.taxEvent;
    } else {
      clickStr = appconstant.applyEvent + appconstant.taxEvent;
    }
    this.setAndApplyTrackdata(clickStr);
  }

  trackApplyTaxClick() {
    let clickStr = appconstant.applyEvent + appconstant.taxEvent 
    this.setAndApplyTrackdata(clickStr);
  }

  trackInactivateMIClick() {
    let clickStr = appconstant.inactivateEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackInactivateMIClickPopup() {
    let clickStr = appconstant.InactivateEventYesConfirmation;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenerateClick() {
    let clickStr = appconstant.generateEvent;
    this.setAndApplyTrackdata(clickStr);
  }
  
  trackGenericOpenClick(origin) {
    let clickStr = appconstant.openEvent + origin;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericCancelClick() {
    let clickStr = appconstant.cancelEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericFinishClick() {
    let clickStr = appconstant.finishEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericGenerateNewClick() {
    let clickStr = appconstant.generateNewEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericAlertClick() {
    let clickStr = appconstant.alertEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericAlertCloseClick() {
    let clickStr = appconstant.alertCloseEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericViewClick() {
    let clickStr = appconstant.viewEvent;
    this.setAndApplyTrackdata(clickStr);
  }
  
  trackGenericComboClick(selectionName) {
    let clickStr = selectionName;
    this.setAndApplyTrackdata(clickStr);
  }

  trackGenericMessageClick() {
    let clickStr = appconstant.messageEvent;
    this.setAndApplyTrackdata(clickStr);
  }
  
  trackGenericHeaderMessageClick() {
    let clickStr = appconstant.messageHeaderEvent;
    this.setAndApplyTrackdata(clickStr);
  }

  trackStepperClick(stepperClick) {
    let trackData = {
      "pageName": this.getPageName(this.router.url),
      "pageType": appconstant.pageType,
      "pageUrl": this.router.url,
      "clickName": stepperClick,
      "clickTrackSection": appconstant.topNav,
      "linkType": appconstant.button,
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(trackData);
    this.trackUserAction();
  }

  trackCancelClick(cancelClick) {
    let trackData = {
      "pageName": this.getPageName(this.router.url),
      "pageType": appconstant.pageType,
      "pageUrl": this.router.url,
      "clickName": appconstant.cancelEvent + ":" + cancelClick,
      "clickTrackSection": appconstant.clickTrackSection,
      "linkType": appconstant.ctaLinkType,
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(trackData);
    this.trackUserAction();
  }

    /*Begin HEADER Track Section */
    trackHeaderLogoClick() {
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let clickType = "";
      let target = HEADER_CONST.clickTypeTargetLogo;
      let clickTrackSection = HEADER_CONST.clickTrackSection;
      let linkType = HEADER_CONST.linkType;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }
  
    trackHeaderLogoutClick() {
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let clickType = "";
      let target = HEADER_CONST.clickTypeTargetLogout;
      let clickTrackSection = HEADER_CONST.clickTrackSection;
      let linkType = HEADER_CONST.linkTypeIcon;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }
  
    
    /*End HEADER Track Section*/

    trackOpenMessageClick(){
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let clickType = OOHOMEPAGE_CONST.clickTypeOpen;
      let target = OOHOMEPAGE_CONST.clickNameTarget;
      let clickTrackSection = OOHOMEPAGE_CONST.clickTrackSection;
      let linkType = OOHOMEPAGE_CONST.linkType;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }
  
    trackSystemAlertMessageClick(){
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let target = SYSMSG_CONST.clickTypeTarget;
      let clickType = "";
      let clickTrackSection = SYSMSG_CONST.clickTrackSection;
      let linkType = SYSMSG_CONST.linkType;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }
  
    genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType) {
      let pageName;
      let pageType;
      var splitted = currentURLPage.split("/", 3); 
      const separatorCharacter:string = '|'; 
      const specificPages:string = PTA_CONST.EMPTY_STRING.concat("System Alert",separatorCharacter,"Header",separatorCharacter,"Content");
      currentURLPage = splitted[1];
      if(splitted[2] != null){
        if(currentURLPage.includes("message-management")){
          currentURLPage = splitted[2];
          if(currentURLPage.includes("isEdit=true")){
            currentURLPage = "edit-message"
          }
        }
        else if(currentURLPage.includes("action-item")){
          currentURLPage = currentURLPage.concat("/",splitted[2]).split('?')[0];
        }
        else if(currentURLPage.includes("owneroperatorview") && (splitted[2].includes("ptaView") || splitted[2].includes("miiView"))){
          currentURLPage = currentURLPage.concat("/",splitted[2]);
        }
      }
      switch (currentURLPage){
        case 'owner-operator-homepage':
          let currentURLPageName:string = this.getPageName(currentURLPage);
          pageName = currentURLPageName === OOHOMEPAGE_CONST.pageName && (specificPages.indexOf(clickTrackSection)) >= PTA_CONST.ZERO? OOHOMEPAGE_CONST.pageNameLoggedIn : this.getPageName(currentURLPage);
          pageType = currentURLPageName === OOHOMEPAGE_CONST.pageName && (specificPages.indexOf(clickTrackSection)) >= PTA_CONST.ZERO? OOHOMEPAGE_CONST.pageType : SYSMSG_CONST.pageTypeModal;
          break;
        case 'pta':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'menu-item-inactivation':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'generateReport':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'pos':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'packagegeneration':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'message-management':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'messages':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'action-item':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'action-item/create':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'action-item/edit':
            pageName = this.getPageName(currentURLPage);
            pageType = appconstant.pageType;
            break;  
        case 'create-message':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'edit-message':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'generatedpackages':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'owneroperatorview/miiView':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;
        case 'owneroperatorview/ptaView':
            pageName = this.getPageName(currentURLPage);
            pageType = appconstant.pageType;
            break;
        case 'mass-mi-delete':
          pageName = this.getPageName(currentURLPage);
          pageType = appconstant.pageType;
          break;           
      }
      this.genericTrackingKeys(pageName, pageType, clickType, target, clickTrackSection, linkType);
    }
  
    genericTrackingKeys (pageName, pageType, clickType, target, clickTrackSection, linkType){
      let trackData = {};
      this.trackingKeys.forEach(key => {
        switch(key) {
          case 'clickName':
            trackData[key] =  clickType + target;
            break;
          case 'pageUrl':
            trackData[key] = this.router.url;
            break;
          case 'pageName':
            trackData[key] = pageName;
            break;
          case 'pageType':
              trackData[key] = pageType;
              break;
          case 'clickTrackSection':
              trackData[key] = clickTrackSection;
              break;
          case 'linkType':
              trackData[key] = linkType;
              break;
          default:
            trackData[key] = appconstant[key];
        }
      });
      this.setAnalyticsData(trackData);
      this.trackUserAction();
    }
  
    getPageName(origin){
      var pageName;
      var setUrl = this.router.url;
      if(origin.includes('owneroperatorview/ptaView')) {
        pageName = ACTION_CONST.PTAInterimPage;
      }
      else if (origin.includes('owneroperatorview/miiView')) {
        pageName = ACTION_CONST.MIIInterimPage;
      }
      else if(origin.includes('owner-operator-homepage')){
        pageName = OOHOMEPAGE_CONST.pageName;
      }
      else if(origin.includes('ptaView')) {
        pageName = ACTION_CONST.PTAInterimPage;
      } else if (origin.includes('miiView')) {
        pageName = ACTION_CONST.MIIInterimPage;
      }
      else if(origin.includes('pta')){
        pageName = PTA_CONST.pageName;
      }
      //RFM-25496
      else if(origin.includes('menu-item-inactivation')){
       if(setUrl.includes('confirmation')) {
          pageName = MII_CONST.pageName + ": " + MII_CONST.confirmation;
        }
        else {
          pageName = MII_CONST.pageName;
        }
      }
      else if(origin.includes('generateReport')){
        pageName = ReportTypeConstant.pageName;
      }
      //RFM-25496
      else if(origin.includes('pos')){
        if (setUrl.indexOf('section') !== -1 ) {
          pageName = POS_SLECTION_CONSTANT.pageName;
        }
        else if (setUrl.indexOf('confirm') !== -1 ) {
          pageName = POS_SLECTION_CONSTANT.analyticsData.confirmationPageView.pageName;
        }
        else {
          pageName = POS_SLECTION_CONSTANT.pageName;
        }
    }
      else if(origin.includes('generatedpackages')) {
        pageName = PG_CONSTANTS.generatedPageName;
      } 
      //RFM-24434
      //RFM-25496
      else if(origin.includes('packagegeneration')){
        if (setUrl.indexOf('confirmation') !== -1 ) {
          pageName = PG_CONSTANTS.confirmationPageName;
        }
        else {
          pageName = PG_CONSTANTS.pageName;
        }
      } 
      else if(origin.includes('create-message')) {
        pageName = MSG_CONST.Messages + ": " + MSG_CONST.MSG_MGMT + ": " + MSG_CONST.ClickName;
      }
      else if(origin.includes('messages')) {
        pageName = MSG_CONST.Messages;
      }
      else if(origin.includes('message-management')) {
        pageName = MSG_CONST.Messages + ": " + MSG_CONST.MSG_MGMT;
      }
      else if(origin.includes('action-item/create')) {
        pageName = ACTION_CONST.ActionItemPage + ": " + ACTION_CONST.ActionItemCreation
      }
      else if(origin.includes('action-item/edit')) {
        pageName = ACTION_CONST.ActionItemPage + ": " + ACTION_CONST.ActionItemEdition
      }
      else if(origin.includes('action-item')) {
        pageName = ACTION_CONST.ActionItemPage;
      }
      else if(origin.includes('create-message')) {
        pageName = MSG_CONST.Messages + ":" + MSG_CONST.MSG_MGMT + ":" + MSG_CONST.MessageManagementCreate;
      }          
      else if(origin.includes('edit-message')) {
        pageName = MSG_CONST.Messages + ":" + MSG_CONST.MSG_MGMT + ":" + MSG_CONST.MessageManagementEdit;
      }     
     else if(origin.includes('mass-mi-delete')){
        if (setUrl.indexOf('review') !== -1 ) {
          pageName = MMID_CONSTANTS.analyticsData.MMIDReviewPageView.pageName;
        }
        else if (setUrl.indexOf('delete-confirm') !== -1 ) {
          pageName = MMID_CONSTANTS.analyticsData.MMIDConfirmationPageView.pageName;
        }
        else {
          pageName = appconstant.MMIDPageName;
        }
    }      
    return pageName;
    }

    trackSystemAlertDismissModalClick(){
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let target = SYSMSG_CONST.clickNameOK;
      let clickType = "";
      let clickTrackSection = SYSMSG_CONST.clickTrackSectionModal;
      let linkType = SYSMSG_CONST.linkType;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }

    trackSystemAlertDismissModalCloseClick(){
      this.resetAnalyticsData();
      let currentURLPage = this.router.url;
      let target = SYSMSG_CONST.clickNameClose;
      let clickType = "";
      let clickTrackSection = SYSMSG_CONST.clickTrackSectionModal;
      let linkType = SYSMSG_CONST.linkType;
      this.genericSetAndApplyTrackdata(currentURLPage, clickType, target, clickTrackSection, linkType);
    }

  trackCreateMessageBtnClick() {
    let clickEvent = MSG_CONST.ClickName;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackCreateMessageSave() {
    let clickEvent = MSG_CONST.Save;
    this.setAndApplyTrackdata(clickEvent);
  }

  cancelCreateMessageTrack() {
    let clickEvent = MSG_CONST.Cancel;
    this.setAndApplyTrackdata(clickEvent);
  }
  trackCreateActionItemBtnClick() {
    let clickEvent = ACTION_CONST.ClickName;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackPTAInterimNext() {
    let clickEvent = ACTION_CONST.InterimNext;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackPTAInterimDelete() {
    let clickEvent = ACTION_CONST.InterimRemove;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackActionItemSaveClick() {
    let clickEvent = ACTION_CONST.ClickSave;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackActionItemCancelClick() {
    let clickEvent = ACTION_CONST.ClickCancel;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIIConfirmationFinish() {
    let clickEvent = MII_CONST.finish;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIIConfirmationExport(clickEvent) {
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIIInterimDelete() {
    let clickEvent = ACTION_CONST.InterimRemove;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIIInterimNext() {
    let clickEvent = ACTION_CONST.InterimNext;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIIInterimClear() {
    let clickEvent = ACTION_CONST.InterimClear;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIISave() {
    let clickEvent = ACTION_CONST.ClickSave;
    this.setAndApplyTrackdata(clickEvent);
  }

  trackMIISendEmail() {
    let clickEvent = ACTION_CONST.ClickSendMail;
    this.setAndApplyTrackdata(clickEvent);
  }
  
  trackMIICancelEmail() {
    let clickEvent = ACTION_CONST.ClickCancelMail;
    this.setAndApplyTrackdata(clickEvent);
  }

  //RFM-20794
  trackPOSPageView() {
    this.resetAnalyticsData();
    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.pageView

    this.setAnalyticsData(analyticsData);
    this.trackPageView();
  }

  //RFM-20803
  trackPOSCurrentDateClick() {
    this.resetAnalyticsData();
    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.currentDateClick;

    this.setAnalyticsData(analyticsData);
    this.trackUserAction();
  }

  //RFM-20804
  trackPOSFutureDateClick(futureDate: string) {
    this.resetAnalyticsData();
    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.futureDateClick;
    analyticsData.clickName = futureDate;

    this.setAnalyticsData(analyticsData);
    this.trackUserAction();
  }

  //RFM-20866
  trackPOSConfirmationPageView() {
    this.resetAnalyticsData();
    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.confirmationPageView;

    this.setAnalyticsData(analyticsData);
    this.trackPageView();
  }

  //RFM-20869
  trackPOSConfirmationPageDoneClick() {
    this.resetAnalyticsData();
    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.confirmationPageDoneClick;

    this.setAnalyticsData(analyticsData);
    this.trackUserAction();
  }

  //RFM-20867
  trackPOSConfirmationPageDropdownAction(clickName: string) {
    this.resetAnalyticsData();

    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.confirmationPageDropdownAction;

    analyticsData.clickName = clickName;
    this.setAnalyticsData(analyticsData);
    this.trackUserAction();
  }

  //RFM-20868,RFM-21118
  trackPOSConfirmationPageExportAction(exportType: string) {
    this.resetAnalyticsData();

    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.confirmationPageExportAction;

    analyticsData.clickName = exportType;
    this.setAnalyticsData(analyticsData);
    this.trackUserAction();
  }

  //RFM-21084
  trackPOSFutureDateLayoutError() {
    this.resetAnalyticsData();

    let analyticsData = POS_SLECTION_CONSTANT.analyticsData.futurePOSError;

    this.setAnalyticsData(analyticsData);
    this.trackPageView();
  }

  //RFM-22681
  trackClearClick() {
    if (this.router.url.includes('mass-mi-delete')) {
      this.resetAnalyticsData();
      let analyticsData = MMID_CONSTANTS.analyticsData.MMIDClearClick;
      analyticsData.pageUrl = this.router.url;
      this.setAnalyticsData(analyticsData);
      this.trackUserAction();
    }
  }

  //RFM-18108,RFM-24341
  trackPackageGenerationConfirmationPageView() {
    this.resetAnalyticsData();

    this.setAnalyticsData({
      pageName: 'Package Generation Confirmation',
      pageType: 'Modal',
      addInfo: 'Confirmation',
      pageUrl: this.router.url,
    });

    this.trackPageView();
  }  
  trackActionItemNoClick(cancelEvent,pageName) {  
    let actionItemData = {
      'pageName': pageName,
      'pageType': appconstant.pageType,
      'pageUrl': this.router.url,
      'clickName': cancelEvent,
      'events': 'event19',
      'linkType':appconstant.ctaLinkType,
      'clickTrackSection':appconstant.clickTrackSection
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(actionItemData);
    this.trackUserAction();
    
    
  }
  trackMessageCancellationPopup(cancelEvent ,pageName) {  
    let actionItemData = {
      'pageName': pageName,
      'pageType': appconstant.pageType,
      'pageUrl': this.router.url,
      'clickName': cancelEvent,
      'events': 'event19',
      'linkType':appconstant.ctaLinkType,
      'clickTrackSection':appconstant.clickTrackSection
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(actionItemData);
    this.trackUserAction();
  }
  trackEditMessageCancellationPage() {      
    this.resetAnalyticsData();
    let actionItemData = MSG_CONST.analyticsData.MSGEditMessege;
    actionItemData.pageType=this.router.url;
    actionItemData.pageType = appconstant.pageType;
    this.setAnalyticsData(actionItemData);
    this.trackUserAction();
  }
  trackActionItemEditCancellationPage() {      
    this.resetAnalyticsData();
    let editActionItemMessage = ACTION_CONST.analyticsData.ActionItemEditMessege;
    editActionItemMessage.pageType=this.router.url;
    editActionItemMessage.pageType = appconstant.pageType;
    this.setAnalyticsData(editActionItemMessage);
    this.trackUserAction();
  }
  trackMessageMGMTClearClick(clickname) {      
    this.resetAnalyticsData();
    let trackClearAllItems = MSG_CONST.analyticsData.MSG_MGMTClearClick;
    trackClearAllItems.pageType=this.router.url;
    trackClearAllItems.pageType = appconstant.pageType;
    trackClearAllItems.clickName = clickname;    
    this.setAnalyticsData(trackClearAllItems);
    this.trackUserAction();
  }
  trackMessageMGMTViewClick() {      
    this.resetAnalyticsData();
    let trackViewClick = MSG_CONST.analyticsData.MSG_MGMTClearClick;
    trackViewClick.pageType=this.router.url;
    trackViewClick.pageType = appconstant.pageType;
    trackViewClick.clickName = appconstant.viewEvent;
    this.setAnalyticsData(trackViewClick);
    this.trackUserAction();
  }
  trackMessageSearch(clickEvent) {      
    this.resetAnalyticsData();
    let trackMesageCreateAndEdit = MSG_CONST.analyticsData.trackMesageCreateAndEdit;
    trackMesageCreateAndEdit.pageUrl=this.router.url;
    trackMesageCreateAndEdit.pageType = appconstant.pageType;
    trackMesageCreateAndEdit.clickName = clickEvent;
    this.setAnalyticsData(trackMesageCreateAndEdit);
    this.trackUserAction();
  }  
  trackMessageEditSearch(clickEvent) {      
    this.resetAnalyticsData();
    let trackMesageCreateAndEdit = MSG_CONST.analyticsData.MSGEditMessege;
    trackMesageCreateAndEdit.clickName = clickEvent;
    trackMesageCreateAndEdit.pageUrl=this.router.url;
    trackMesageCreateAndEdit.pageType = appconstant.pageType;
    this.setAnalyticsData(trackMesageCreateAndEdit);
    this.trackUserAction();
  }
  trackMessageConfirmationPageView() {
    this.resetAnalyticsData();
    let ConfirmationPageView = MSG_CONST.analyticsData.messageConfirmationPageView;
    this.setAnalyticsData(ConfirmationPageView);
    this.trackPageView()

  }
  trackActionItemOnSearchClick(searchEvent){
    let onSearchItem = ACTION_CONST.analyticsData.ActionItemOnSearch;
    onSearchItem.clickName = searchEvent;
    this.setAnalyticsData(onSearchItem);
    this.trackUserAction();
  }
  trackActionItemRequestCreateOkClick(clickEvent){
    let okClick = ACTION_CONST.analyticsData.ActionItemCreateMessage;
    okClick.clickName = clickEvent;
    this.setAnalyticsData(okClick);
    this.trackUserAction();
  }
  trackActionItemRequestEditOkClick(clickEvent){
    let okClickEditMessage = ACTION_CONST.analyticsData.ActionItemEditMessege;
    okClickEditMessage.clickName = clickEvent;
    this.setAnalyticsData(okClickEditMessage);
    this.trackUserAction();
  }
  trackMsgStatusDropdownList(event){
    this.resetAnalyticsData();
    let trackStatus = MSG_CONST.analyticsData.trackMsgStatusDropDownList;
    trackStatus.pageUrl = this.router.url;
    trackStatus.clickName = event;
    this.setAnalyticsData(trackStatus);
    this.trackUserAction();

  }
  trackMsgFilters(event){
    this.resetAnalyticsData();
    let trackFilters = MSG_CONST.analyticsData.trackMsgFilters;
    trackFilters.pageUrl = this.router.url;
    trackFilters.clickName = event;
    this.setAnalyticsData(trackFilters);
    this.trackUserAction();
  }
  trackActionItemFilters(event){
    this.resetAnalyticsData();
    let trackFilters = MSG_CONST.analyticsData.trackMsgFilters;
    trackFilters.pageUrl = this.router.url;
    trackFilters.clickName = event;
    trackFilters.pageName = ACTION_CONST.ActionItemPage;
    this.setAnalyticsData(trackFilters);
    this.trackUserAction();
  }
  trackMsgRecipientNode(clickName){
    this.resetAnalyticsData();
    let trackRecipientNodes = MSG_CONST.analyticsData.trackMsgRecipientNode;
    trackRecipientNodes.pageUrl = this.router.url;
    trackRecipientNodes.clickName = clickName;
    this.setAnalyticsData(trackRecipientNodes);
    this.trackUserAction();
  }
  trackEditDelete(clickEvent) {      
    this.resetAnalyticsData();
    let trackviewMessage = MSG_CONST.analyticsData.trackMesageCreateAndEdit;
    trackviewMessage.pageUrl=this.router.url;
    trackviewMessage.pageType = appconstant.pageType;
    trackviewMessage.clickName = clickEvent;
    trackviewMessage.pageName = MSG_CONST.MessageViewpageName;
    this.setAnalyticsData(trackviewMessage);
    this.trackUserAction();
  }
  trackMessageType(clickEvent) {      
    this.resetAnalyticsData();
    let trackMessageType = MSG_CONST.analyticsData.trackMessageType;
    trackMessageType.pageUrl=this.router.url;
    trackMessageType.pageType = appconstant.pageType;
    trackMessageType.clickName = clickEvent;
    this.setAnalyticsData(trackMessageType);
    this.trackUserAction();
  }
  trackMsgTypeAdminPage(event){
    this.resetAnalyticsData();
    let type = MSG_CONST.analyticsData.trackMsgFilters;
    type.pageUrl = this.router.url;
    type.clickName = event;
    type.addInfo ="Type";
    type.linkType ="Dropdown";
    this.setAnalyticsData(type);
    this.trackUserAction();
  }
  trackMSgPageview(){
    this.resetAnalyticsData();
    let type = MSG_CONST.analyticsData.trackMSGPageView;
    type.pageUrl = this.router.url;
    this.setAnalyticsData(type);
    this.trackPageView();

  }
  trackActionItemDeleteClick(deleteRecord){
    let deleteItem = ACTION_CONST.analyticsData.ActionItemOnSearch;
    deleteItem.pageName = ACTION_CONST.ActionItemViewPage;    
    deleteItem.clickName = deleteRecord;
    deleteItem.linkType = 'CTA';
    this.setAnalyticsData(deleteItem);
    this.trackUserAction();
  }  
  trackActionItemSearchClick(clickEvent){
    let okClickEditMessage = ACTION_CONST.analyticsData.ActionItemSearchClick;
    okClickEditMessage.pageName = clickEvent;
    this.setAnalyticsData(okClickEditMessage);
    this.trackUserAction();
  } 
  trackActionItemViewClick(clickEvent){
    let okClickEditMessage = ACTION_CONST.analyticsData.ActionItemOnSearch;
    okClickEditMessage.clickName = clickEvent;
    okClickEditMessage.linkType = 'Icon';
    this.setAnalyticsData(okClickEditMessage);
    this.trackUserAction();
  }  
  trackMenuItemsAndRecipeintNode(name , type){
    let menuItemAndRecipeintNodes = ACTION_CONST.analyticsData.ActionItemOnSearch;
    menuItemAndRecipeintNodes.clickName = name;
    menuItemAndRecipeintNodes.linkType = type;
    this.setAnalyticsData(menuItemAndRecipeintNodes);
    this.trackUserAction();
  }
  trackActionItemTypeAndStatus(name , type){
    let typeAndStatus = ACTION_CONST.analyticsData.ActionItemTypeDropdown;
    typeAndStatus.clickName = name;
    typeAndStatus.clickTrackSection = type;
    this.setAnalyticsData(typeAndStatus);
    this.trackUserAction();
  }
  trackMenuItemsStatusClick(name , addinfo){
    let status = ACTION_CONST.analyticsData.ActionItemStatusClick;
    status.clickName = name;
    status.addInfo= addinfo;
    this.setAnalyticsData(status);
    this.trackUserAction();
  }
  navigateToLegacy(clickName ,pagename){
    let trackData = {
      "pageName": pagename,
      "pageType": appconstant.pageType,
      "pageUrl": this.router.url,
      "clickName": clickName,
      "clickTrackSection": appconstant.clickTrackSection,
      "linkType": appconstant.ctaLinkType,
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(trackData);
    this.trackUserAction();
  }
  trackLegacyRFM(){
    let trackData = {
      "pageName": 'Owner-Operator-Homepage',
      "pageType": appconstant.pageType,
      "pageUrl": this.router.url,
      "clickName": appconstant.trackRFMHyperLinkClick,
      "clickTrackSection": appconstant.clickTrackSection,
      "linkType": appconstant.trackRFMHyperLink,
    };
    this.resetAnalyticsData();
    this.setAnalyticsData(trackData);
    this.trackUserAction();
  }
  trackpreviousClick(view){
    let viewTrack = ACTION_CONST.analyticsData.ActionItemOnSearch;
    viewTrack.pageName = 'Messages: Message Management: View Message';    
    viewTrack.clickName = view;
    viewTrack.linkType = 'CTA';
    this.setAnalyticsData(viewTrack);
    this.trackUserAction();
  }  
}
