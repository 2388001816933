import { MessageCreationService } from '../../message-creation/services/message-creation.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { OwnerOperatorHeaderService } from '../../../shared/services/owner-operator-header.service';
import { OwnerOperatorHomepageService } from '../../../shared/services/owner-operator-homepage.service';
import { OwnerOperatorPermissionsService } from '../../../shared/services/owner-operator-permissions.service';
import { Router } from '@angular/router';
import { connection } from '../../../../environments/connection.properties';
import { AuthUserService } from '../../../services/auth-user.service';
import { MiiInactivationService } from '../../menu-item-inactivation/service/mii-inactivation.service';
import { DropdownService } from '../../pta/pta-dropdown/dropdown.service';
import { OOP_URL_CONST } from '../../../shared/constants/rfmOOPUrl.constant';
import { OOP_CONST } from '../../../shared/constants/rfmOOP.constant';
import { Title } from '@angular/platform-browser';
import { AdobeAnalyticsService } from '../../../services/adobe-analytics.service';
import { HeaderService } from '../../../services/header.service';

@Component({
  selector: 'app-owner-operator-header',
  templateUrl: './owner-operator-header.component.html',
  styleUrls: ['./owner-operator-header.component.scss'],

})
export class OwnerOperatorHeaderComponent implements OnInit {

  logoutResponse: any;
  userId: any;
  userEid: any;
  firstName: any;
  lastName: any;
  headerMessageCount: number;
  isGlobalUser: any;
  urlToCall: any;
  messageMap: any;
  userDetails: any;
  isRest: string;
  isVisitedOOP: boolean = false;
  navbarOpen: boolean = true;
  headerMessagePermission: any;
  headerMessagePermissionFlag: boolean = true;
  newLegacyFlag: boolean = false;
  isChangeMadeCast: any;
  //newLegacyPageFlag: boolean = true;
  headerResponse: any = [];
  optionDisplayFlag: boolean = false;
  popupMessage: any;
  responseString: any = [];
  @Input() getnewLegacyFlag: boolean;
  headerMessageRoute: string;
  hideRoot: boolean = true;
  tableHeader: any;

  confirmPopMod: boolean = false;
  cancelHeaderText : string;
  cancelBodyText : string;
  recordChanges : any = '';
  helpGuide:any ='';

  @ViewChild('redirectPopup') private redirectPopupRef: ElementRef;
  @ViewChild('headerRef') private headerElementRef: ElementRef;

  constructor(private ownerOperatorHeaderService: OwnerOperatorHeaderService, private http: HttpClient,
    private localStorageService: LocalStorageService, public ownerOperatorHomepageService: OwnerOperatorHomepageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    public ownerOperatorPermissionsService: OwnerOperatorPermissionsService,
    private router: Router, private messageCreationService: MessageCreationService, private miiService: MiiInactivationService, private AuthUserService: AuthUserService,
    private dropDownService: DropdownService,
    private headerService: HeaderService,
    private adobeAnalyticsService: AdobeAnalyticsService) {}

  ngOnInit() {
    this.tableHeader = document.getElementsByClassName('pos-section-wrap');
    this.redirectPopupRef.nativeElement.style.display = 'none';
    this.headerElementRef.nativeElement.style.display = 'none';
    setInterval(() => {
      this.redirectPopupRef.nativeElement.style.display != 'none';
      this.headerElementRef.nativeElement.style.display = 'block';
    }, 90);

    this.userEid = this.sessionStorage.get(OOP_CONST.USER_EID);
    this.firstName = this.sessionStorage.get(OOP_CONST.FIRST_NAME);
    this.lastName = this.sessionStorage.get(OOP_CONST.LAST_NAME);
    this.isGlobalUser = this.sessionStorage.get(OOP_CONST.IS_GLOBAL_USER);
    this.isRest = this.sessionStorage.get(OOP_CONST.IS_REST);
    this.headerMessageRoute = OOP_URL_CONST.MESSAGE_MANAGEMENT_MESSAGES_PAGE;

    this.retrieveRFMRefreshMessages();
    setInterval(() => {
      this.ownerOperatorHeaderService.checkSession(OOP_URL_CONST.RFM_CHECKSESSION_URL, '').then((successResponse) => {
        if (successResponse != null) {
          this.responseString = successResponse;
          if (this.responseString != null || this.responseString != undefined || this.responseString != OOP_CONST.SHOW_WARNING_MESSAGE_1) {
            let time = this.responseString.split(OOP_CONST.SYMBOL_COLON);
            if (time[1] != undefined) {
              this.popupMessage = this.messageMap.OOP_timeoutMessage1 + " " + time[1] + " " + this.messageMap.OOP_timeoutMessages2 + ". " + this.messageMap.OOP_timeoutMessage3;
              this.optionDisplayFlag = true;
            }

          }
          else if (this.responseString == OOP_CONST.SHOW_WARNING_MESSAGE_1) {
            let time = this.responseString.split(OOP_CONST.SYMBOL_COLON);
            this.popupMessage = this.messageMap.OOP_timeoutMessage1 + " " + time[1] + " " + this.messageMap.OOP_timeoutMessage2 + ". " + this.messageMap.OOP_timeoutMessage3;
            this.optionDisplayFlag = true;
          }
          if (this.responseString == OOP_CONST.NULL_SESSION) {
            window.location.href = OOP_CONST.SYMBOL_SLASH + window.location.host + OOP_URL_CONST.RFM_LOGOUT_ACTION_URL;
          }
        }


      });
    }, 60000);


    this.ownerOperatorHeaderService.isChangeMadeCast.subscribe(cast => this.isChangeMadeCast = cast);
    //this.newLegacyPageFlag =this.getnewLegacyFlag;// this.ownerOperatorHeaderService.validateLegacyRFMPage(); //
    this.headerResponse = [OOP_CONST.PERMISSION_OWNER_OPERATOR_MESSAGES];
    this.headerMessagePermission = this.ownerOperatorPermissionsService.ownerOperatorPermissions(OOP_CONST.PERMISSION_OWNER_OPERATOR_MESSAGES);
    if (!(this.headerMessagePermission == 1 || this.headerMessagePermission == 2 || this.headerMessagePermission == 3)) {
      this.headerMessagePermissionFlag = false;
    }
    if (this.headerMessagePermissionFlag) {
      this.ownerOperatorHomepageService.getNotificationCount(OOP_CONST.NOTIFICATION_COUNT, this.headerResponse).subscribe((data) => {
        this.ownerOperatorHomepageService.setMessageHeaderCount(data[OOP_CONST.MESSAGE_NOTIFICATION_COUNT]);
        this.ownerOperatorHomepageService.messageCountTrack.subscribe((count) => this.headerMessageCount = count);
      });
    }
    this.headerService.isChangeMadeCast.subscribe(cast =>{
      console.log(cast)
      if (cast != false) {
        this.helpGuide = cast;
      } else {
        this.helpGuide = ''
      }  
      setTimeout(() => {this.helpGuide = ''}, 10000)})
  }



  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.toggleClassForPos();
  }
  public navbarStatus(event) {
    this.navbarOpen = event;
    this.toggleClassForPos();
  }

  toggleClassForPos() {
    if(this.tableHeader[0]) {
      this.tableHeader[0].classList.toggle('pos-scroll-hide');
    }
  }

  retrieveRFMRefreshMessages() {
    this.userDetails = this.sessionStorage.get(OOP_CONST.USER_DETAILS);
    if (this.userDetails && this.userDetails[OOP_CONST.MESSAGEMAP]) {
      this.messageMap = this.userDetails[OOP_CONST.MESSAGEMAP];
    }
  }

  public logout() {
    this.adobeAnalyticsService.trackHeaderLogoutClick();
    if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH == 'true') {
      this.urlToCall = location.protocol + OOP_CONST.SYMBOL_SLASH + location.hostname + OOP_CONST.SYMBOL_COLON +
        connection.RELATIVE_API_PORT + connection.RFM_HOME_API_PATH;
    } else {
      this.urlToCall = connection.RFM_HOME_FULL_URL;
    }

    this.adobeAnalyticsService.logout(this.sessionStorage.get("userEid"));

    let url = this.urlToCall
      + OOP_URL_CONST.RFM_LOGOUT_ACTION_URL;

    window.open(url, OOP_CONST.SELF);
    this.ownerOperatorHeaderService.OOPlogout();
  }

    
  cancelActionPopup(actionName) {
    this.confirmPopMod = false;
    actionName = actionName.trim();
    switch(actionName) {
      case this.messageMap.PG_Yes:
        
      if (this.router.url == "/mass-menu-item-activate-inactivate/selection" ||  "/mass-menu-item-activate-inactivate/review" ) {
        this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
        this.confirmPopMod = false;
      }
      else{
        this.redirectToLegacyPage(OOP_CONST.SELF);
      }
        break;
      case this.messageMap.PG_No:
        break;
    }
    }

  public cancelConfirmFromHeader () {
    let dimensionChanges = this.sessionStorage.get("dimensionChanges");
    if (dimensionChanges != undefined && dimensionChanges > 0) {
      this.confirmPopMod = true;
    } else {
      this.redirectToLegacyPage(OOP_CONST.SELF);
    }
  }

  public cancelFromHeader () {
     this.recordChanges = this.sessionStorage.get("changesMade")
    let url = this.router.url == "/mass-menu-item-activate-inactivate/selection" || this.router.url == "/mass-menu-item-activate-inactivate/review";
if (url) {
  if (this.recordChanges != undefined && this.recordChanges > 0) {      
    this.confirmPopMod = true;     
    this.cancelHeaderText = this.messageMap.massMenuItemActivateCancelTitle;
    this.cancelBodyText =  this.messageMap.massMenuItemActivateMCDMsg;               
    } else{
      this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
    }
}       
    }

  public redirectFromHeaderLogo() {
    this.adobeAnalyticsService.trackHeaderLogoClick();

    this.navbarStatus(event);
    
    if (this.isChangeMadeCast == true && (location.pathname == OOP_URL_CONST.MASS_MENU_ITEM_INACTIVATION_INACTIVATION_PAGE)) {
      this.miiService.cancelConfirmFromHeader();
    }
    else if ((location.pathname == OOP_URL_CONST.ACTION_ITEM_EDIT_PAGE)) {
      this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
    }
    else if ((location.pathname == OOP_URL_CONST.ACTION_ITEM_CREATE_PAGE)) {
      this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
    }
     else if (location.pathname == OOP_URL_CONST.PRICE_TAX_ACTIVATE_PRICE_PAGE || location.pathname == OOP_URL_CONST.PRICE_TAX_ACTIVATE_TAX_PAGE) {
      this.dropDownService.cancelConfirmFromHeader(true);
    }
    else if ((location.pathname == OOP_URL_CONST.MESSAGE_MANAGEMENT_CREATE_MESSAGE_PAGE || location.pathname == OOP_URL_CONST.MESSAGE_MANAGEMENT_EDIT_MESSAGE_PAGE)) {
      this.messageCreationService.cancelConfirmFromHeader();
    }
    else if (location.pathname == OOP_URL_CONST.ACTION_ITEM_OWNER_OPERATOR_VIEW_PTA_VIEW_PAGE || location.pathname == OOP_URL_CONST.ACTION_ITEM_OWNER_OPERATOR_VIEW_MII_VIEW_PAGE) {
      this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
    }        
    else if (location.pathname == OOP_URL_CONST.DIMENSIONGROUP_PAGE 
            || location.pathname == OOP_URL_CONST.DIMENSIONGROUP_CREATE_PAGE) {
      this.cancelHeaderText = this.messageMap.dimension_cancel_Header;
      this.cancelBodyText = this.messageMap.dimension_cancel_body;
      this.cancelConfirmFromHeader();
    }
    else if ((location.pathname == OOP_URL_CONST.DIMENSIONGROUP_PAGE || location.pathname == OOP_URL_CONST.DIMENSIONGROUP_EDIT_PAGE)) {
      this.cancelHeaderText = this.messageMap.dimension_cancel_Header;
      this.cancelBodyText = this.messageMap.dimension_cancel_body;
      this.cancelConfirmFromHeader();
    }  

    else if ((location.pathname == OOP_URL_CONST.MASS_MENU_ITEM_ACT_INACTIVATION_SELECTION_PAGE || location.pathname == OOP_URL_CONST.MASS_MENU_ITEM_ACT_INACTIVATION_REVIEW_PAGE) ) {    
      this.cancelFromHeader();
    }  

    else {
      let windowOpenIn = OOP_CONST.BLANK;
      if (this.getnewLegacyFlag) {
        windowOpenIn = OOP_CONST.SELF;
        this.redirectToLegacyPage(windowOpenIn);
      }
      else {
        if (location.pathname == OOP_URL_CONST.GENERATE_REPORT_LIST_PAGE || location.pathname == OOP_URL_CONST.PACKAGE_GENERATION_PAGE)
          this.router.navigateByUrl(OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE);
        else
          window.location.href = OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE
      }
    }
  }




  public redirectToLegacyPage(windowOpenIn) {
    if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH == 'true') {
      this.urlToCall = location.protocol + OOP_CONST.SYMBOL_SLASH + location.hostname + OOP_CONST.SYMBOL_COLON +
        connection.RELATIVE_API_PORT;
    } else {
      this.urlToCall = connection.RFM_HOME_FULL_URL;
    }

    if (this.isRest == OOP_CONST.R)
      this.isVisitedOOP = true;
    else
      this.isVisitedOOP = false;


    if (this.sessionStorage.get('isSessionStorage') == "true") {
      if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH === 'true') {
        if (connection.HEADER_ENV === 'AWSRDI') {
          let url = this.sessionStorage.get("ptaURL")
            + '/rfm2OnlineApp/rfmLogin.action?'
            + 'iv-user=' + this.sessionStorage.get('userEid')
            + '&paramLogin=' + connection.PARAM_LOGIN
            + '&refresh=yes'
            + '&isVisitedOOPPage=' + this.isVisitedOOP;

          window.open(url, windowOpenIn);
        } else {
          let url = this.sessionStorage.get("ptaURL")
            + '/rfm2OnlineApp/rfmLogin.action?'
            + 'isVisitedOOPPage=' + this.isVisitedOOP;

          window.open(url, windowOpenIn);
        }
      } else {
        let url = this.urlToCall
          + '/rfm2OnlineApp/rfmLogin.action?'
          + 'iv-user=' + this.sessionStorage.get('userEid')
          + '&paramLogin=' + connection.PARAM_LOGIN
          + '&refresh=yes'
          + '&isVisitedOOPPage=' + this.isVisitedOOP;

        window.open(url, windowOpenIn);
      }
    }
    else {
      if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH === 'true') {
        if (connection.HEADER_ENV === 'AWSRDI') {
          let url = this.localStorageService.get("ptaURL")
            + '/rfm2OnlineApp/rfmLogin.action?'
            + 'iv-user=' + this.localStorageService.get("userEid")
            + '&paramLogin=' + connection.PARAM_LOGIN
            + '&refresh=yes'
            + '&isVisitedOOPPage=' + this.isVisitedOOP;
          window.open(url, windowOpenIn);
        } else {
          let url = this.localStorageService.get("ptaURL")
            + '/rfm2OnlineApp/rfmLogin.action?'
            + 'isVisitedOOPPage=' + this.isVisitedOOP;

          window.open(url, windowOpenIn);
        }
      } else {
        let url = this.urlToCall
          + '/rfm2OnlineApp/rfmLogin.action?'
          + 'iv-user=' + this.localStorageService.get("userEid")
          + '&paramLogin=' + connection.PARAM_LOGIN
          + '&refresh=yes'
          + '&isVisitedOOPPage=' + this.isVisitedOOP;

        window.open(url, windowOpenIn);
      }
    }
  }
  signedIn() {
    this.ownerOperatorHeaderService.checkSession(OOP_URL_CONST.LOGIN_URL, '').then((users) => { });
    this.optionDisplayFlag = false;

  }

  public ckeckHeaderMessagePermission() {
    //To Hide message header icon from admin screen and if user don't have message permissions//
    if (this.headerMessagePermissionFlag === false || this.getnewLegacyFlag === true)
      return false;
    else
      return true;
  }

  openMessageRoute(link) {
    this.adobeAnalyticsService.trackGenericHeaderMessageClick();
    this.router.navigate([link]);
  }
  navigateToLegacyPage(event,pagnename){
    this.adobeAnalyticsService.navigateToLegacy(event , pagnename)
  }

}

